import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer-head">
        <div className="footer-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="footer-banner-heading">
                <span className="footer-heading-span">GET </span> YOUR{" "}
                <span className="footer-heading-span">
                  WEEKLY FITNESS PLAN{" "}
                </span>
                FROM <span className="footer-heading-span">NAJI</span>
              </h2>
            </div>
            <div className="col-md-4 offset-md-2 align-self-center">
              <a href="contact" className="footer-banner-btn text-decoration-none">hire Widad naji</a>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-sm-3">
              <a href="#">
                <img
                  src="../../assets/images/widi-logo-plan.png"
                  className="logo-fluid"
                  loading="lazy"
                />
              </a>
            </div>
            <div className="col-6 col-md-2">
              <div className="footer-nav d-flex flex-column align-items-center">
                <h6 className="footer-heading">Fitness</h6>
                <a href="blogs">Stay Motivated</a>
                <a href="blogs">Getting Fit</a>
                <a href="blogs">What to Eat</a>
                <a href="blogs">Mindset</a>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className="footer-nav d-flex flex-column align-items-center">
                <h6 className="footer-heading">Navigate</h6>
                <a href="my-story">My Story</a>
                <a href="success-stories">Success Stories</a>
                <a href="contact">Contact</a>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className="footer-nav d-flex flex-column align-items-center">
                <h6 className="footer-heading">Instagram</h6>
                <a href="https://www.instagram.com/coach_widi" target="_blank">
                  @coach_wid
                </a>
              </div>
            </div>
            <div className="col-6 col-md-2">
              <div className="footer-nav d-flex flex-column align-items-center">
                <h6 className="footer-heading">Social</h6>
                <a
                  href="https://www.youtube.com/user/MorelliFit"
                  target="_blank"
                >
                  YouTube
                </a>
                <a href="https://twitter.com/morellifit" target="_blank">
                  Twitter
                </a>
                <a href="https://www.facebook.com/MorelliFit" target="_blank">
                  Facebook
                </a>
              </div>
            </div>
          </div>
          <div className="vertical-divider"></div>
          <div className="footer-foot-wrapper">
            <small className="footer-foot-text">
              ©2024 WidadNaji, LLC. All rights reserved.
            </small>
            <div className="d-flex flex-column">
              <a href="terms-of-use" className="footer-foot-link">Terms of Use</a>
              <a href="privacy-policy" className="footer-foot-link">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
