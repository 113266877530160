import React from "react";
import Home from "./pages/home/home";
import Contact from "./pages/contact/Contact";
import Blog from "./pages/blog/blog";
import SuccessStories from "./pages/success-stories/SuccessStories";
import MyStory from "./pages/my-story/myStory";
import PrivacyPolicy from "./pages/privacy-policy/privacyPolicy";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

const routes = [
  {
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/blogs",
        element: <Blog />,
      },
      {
        path: "/success-stories",
        element: <SuccessStories />,
      },
      {
        path: "/my-story",
        element: <MyStory />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-of-use",
        element: <TermsOfUse />,
      },
    ],
  },
  {
    path: "*",
    element: <h2>Not fount</h2>,
  },
];
const Router = createBrowserRouter(routes);

export default Router;
