import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const TermsOfUse = () => {
  return (
    <React.Fragment>
      <div style={{ background: "#f05" }}>
        <div className="container">
          <Header />
        </div>
      </div>
      <section
        className="container"
        style={{ marginTop: "60px", marginBottom: "100px" }}
      >
        <h2>Terms Of Use</h2>
        <p>
          {" "}
          <em>
            Welcome to Widad Naji's personal fitness website. These Terms of Use
            govern your access to and use of our website and services. By
            accessing or using our website, you agree to comply with these Terms
            of Use. If you do not agree with these terms, please do not use our
            website.
          </em>
        </p>
        <h4>
          <strong>Use of Website</strong>
        </h4>
        <p>
          <b>a. Permitted Use: </b>You may use our website for personal,
          non-commercial purposes in accordance with these Terms of Use. <br />
          <b>b. Prohibited Conduct: </b>You agree not to engage in any conduct
          that could damage, disable, overburden, or impair our website or
          interfere with any other party's use of our website. This includes but
          is not limited to: <br />
          <ul>
            <li>
              Attempting to gain unauthorized access to any portion of our
              website, server, or network.
            </li>
            <li>Transmitting any viruses, worms, or other harmful code.</li>
            <li>
              Interfering with the security or integrity of our website or its
              content.
            </li>
          </ul>
        </p>
        <h4>
          <strong>Intellectual Property Rights</strong>
        </h4>
        <p>
          <b>a. Ownership: </b>All content and materials available on our
          website, including text, graphics, logos, images, audio clips, and
          software, are the property of Widad Naji or its licensors and are
          protected by copyright, trademark, and other intellectual property
          laws. <br />
          <b>b. License: </b> We grant you a limited, non-exclusive,
          non-transferable license to access and use our website and its content
          for personal, non-commercial purposes only. This license does not
          permit you to modify, reproduce, distribute, or create derivative
          works based on our website or its content without our prior written
          consent.
          <br />
        </p>
        <h4>
          <strong>User Content</strong>
        </h4>
        <p>
          <b>a. Submission: </b>If you submit any content to our website,
          including comments, reviews, or other materials, you grant us a
          perpetual, irrevocable, worldwide, royalty-free license to use,
          modify, adapt, publish, translate, and distribute such content in any
          form, media, or technology. <br />
          <b>b. Responsibility:</b> You are solely responsible for any content
          you submit to our website. By submitting content, you represent and
          warrant that you have the right to do so and that the content is
          accurate, not confidential, and does not violate any third-party
          rights or applicable laws.
        </p>
        <h4>
          <strong>Privacy</strong>
        </h4>
        <p>
          Your use of our website is subject to our Privacy Policy, which
          governs the collection, use, and disclosure of your personal
          information. By using our website, you consent to the collection and
          use of your information as described in our Privacy Policy.
        </p>
        <h4>
          <strong>Disclaimer of Warranties</strong>
        </h4>
        <p>
          <b>a. No Warranty: </b>Our website and its content are provided on an
          "as is" and "as available" basis without any warranties of any kind,
          either express or implied. We do not warrant that our website will be
          uninterrupted or error-free, or that any defects will be corrected.{" "}
          <br />
          <b>b. Use at Your Own Risk: </b> You use our website at your own risk.
          We disclaim all warranties, express or implied, including but not
          limited to warranties of merchantability, fitness for a particular
          purpose, and non-infringement.
        </p>
        <h4>
          {" "}
          <strong>Limitation of Liability</strong>
        </h4>
        <p>
          <b>a. Exclusion of Damages: </b>In no event shall Widad Naji, its
          affiliates, or its licensors be liable for any indirect, incidental,
          special, consequential, or punitive damages arising out of or in
          connection with your use of our website or its content, even if we
          have been advised of the possibility of such damages. <br />
          <b>b. Maximum Liability: </b>Our total liability to you for any
          damages arising out of or in connection with your use of our website
          or its content shall not exceed the amount paid by you, if any, for
          accessing our website.
        </p>
        <h4>
          {" "}
          <strong>Indemnification</strong>{" "}
        </h4>
        <p>
          You agree to indemnify, defend, and hold harmless Widad Naji, its
          affiliates, and its licensors from and against any and all claims,
          liabilities, damages, losses, costs, expenses, or fees (including
          reasonable attorneys' fees) arising out of or in connection with your
          use of our website, your violation of these Terms of Use, or your
          violation of any rights of any other person or entity.
        </p>
        <h4>
          <strong>Governing Law and Dispute Resolution</strong>
        </h4>
        <p>
          These Terms of Use shall be governed by and construed in accordance
          with the laws of [insert jurisdiction]. Any dispute arising out of or
          in connection with these Terms of Use shall be resolved exclusively in
          the state or federal courts located in [insert jurisdiction], and you
          hereby consent to the personal jurisdiction of such courts.
        </p>
        <h4>
          <strong>Changes to Terms of Use</strong>
        </h4>
        <p>
          We reserve the right to update or modify these Terms of Use at any
          time without prior notice. Any changes will be effective immediately
          upon posting the revised terms on our website. Your continued use of
          our website after the posting of changes constitutes your acceptance
          of such changes.
        </p>
        <h4>
          <strong>Contact Us</strong>
        </h4>
        <p>
          If you have any questions or concerns about these Terms of Use, please{" "}
          <a href="contact/" target="_blank">
            contact us
          </a>{" "}
          By using our website, you agree to abide by these Terms of Use. If you
          do not agree with any part of these terms, please do not use our
          website.
        </p>
        <h2 className="elementor-heading-title elementor-size-default">
          Privacy Policy
        </h2>
        <p>
          Thank you for visiting the website of Widad Naji, a personal fitness
          trainer committed to helping individuals achieve their fitness goals.
          Protecting your privacy is important to us. This Privacy Policy
          outlines how we collect, use, disclose, and safeguard your personal
          information when you visit our website or engage with our services.{" "}
        </p>
        <h4>
          <strong>Information We Collect</strong>
        </h4>

        <p>
          <b>a. Personal Information:</b> When you visit our website or interact
          with our services, we may collect personal information that you
          voluntarily provide to us. This may include your name, email address,
          phone number, billing information, and any other information you
          choose to provide. <br />
          <b>b. Automatically Collected Information: </b> We may also
          automatically collect certain information about your device and usage
          patterns, such as your IP address, browser type, operating system,
          referring URLs, and other technical information.{" "}
        </p>

        <h4>
          <strong>How We Use Your Information</strong>
        </h4>

        <p>
          <b>a. Providing Services: </b> We may use the information we collect
          to provide you with our services, including scheduling appointments,
          delivering personalized fitness plans, and communicating with you
          about your fitness goals.
          <br />
          <b>b. Improving Services: </b> We may use the information to analyze
          trends, track user movements, and improve the overall user experience
          of our website and services.
          <br />
          <b>c. Marketing and Communication: </b> With your consent, we may use
          your contact information to send you promotional materials,
          newsletters, and other marketing communications related to our
          services. You can opt out of receiving these communications at any
          time. <br />
          <b>d. Legal Compliance: </b> We may use your information to comply
          with applicable laws, regulations, or legal processes, or to protect
          our rights, property, or safety, as well as those of our users or
          others.
          <br />
        </p>
        <h4>
          <strong>How We Share Your Information</strong>
        </h4>
        <p>
          <b>a. Service Providers: </b> We may share your information with
          third-party service providers who assist us in operating our website,
          conducting our business, or providing services to you. These service
          providers are contractually obligated to use your information only as
          necessary to provide the services requested by us. <br />
          <b>b. Legal Requirements: </b> We may disclose your information if
          required to do so by law or in response to valid legal requests, such
          as court orders, subpoenas, or government investigations.
          <br />
          <b>c. Business Transfers: </b> In the event of a merger, acquisition,
          or sale of all or a portion of our assets, your information may be
          transferred as part of the transaction. You will be notified via email
          and/or a prominent notice on our website of any change in ownership or
          uses of your personal information, as well as any choices you may have
          regarding your information.
          <br />
        </p>
        <h4>
          <strong>Your Choices and Rights</strong>
        </h4>
        <p>
          <b>a. Opt-Out: </b> You may opt out of receiving marketing
          communications from us by following the unsubscribe instructions
          included in the communication or by contacting us directly.
          <br />
          <b>b. Access and Correction: </b> You have the right to access and
          update the personal information we hold about you. You may also
          request that we correct any inaccuracies in your information.
          <br />
          <b>c. Data Deletion: </b> You may request the deletion of your
          personal information from our systems, subject to certain legal
          obligations or legitimate business interests.
          <br />
        </p>
        <h4>
          <strong>Security of Your Information</strong>
        </h4>
        <p>
          We take reasonable measures to protect the security of your personal
          information against unauthorized access, alteration, disclosure, or
          destruction. However, no method of transmission over the internet or
          electronic storage is completely secure, so we cannot guarantee
          absolute security.
        </p>
        <h4>
          <strong>Children's Privacy</strong>
        </h4>
        <p>
          Our website and services are not directed to individuals under the age
          of 18. We do not knowingly collect personal information from children
          under 18. If you are a parent or guardian and become aware that your
          child has provided us with personal information without your consent,
          please contact us so that we can take appropriate action.
        </p>
        <h4>
          <strong>Changes to This Privacy Policy</strong>
        </h4>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting the
          revised policy on this page. We encourage you to review this Privacy
          Policy periodically for any updates.
        </p>
        <h4>
          <strong>Contact Us</strong>
        </h4>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please{" "}
          <a href="contact/" target="_blank">
            contact us
          </a>
          . <br />
          By using our website or services, you consent to the collection, use,
          and disclosure of your information as described in this Privacy
          Policy.
        </p>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default TermsOfUse;
