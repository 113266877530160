import React from "react";
import "./BlogCard.css";

const BlogCard = () => {
  return (
    <React.Fragment>
      <div className="col-md-6 col-lg-4">
        <a href="#" className="blog-card text-decoration-none">
          <img
            src="../assets/images/smashing-through-communication-barriers-768x480.jpg"
            loading="lazy"
          />
          <div className="blog-card-body">
            <h4 className="blog-heading">
              How to Overcome Your Past to Build Your Future
            </h4>
            <p className="blog-desc truncate-2">
              We all have past conditioning we want to let go of, but how do we
              do it? Well, here is how I am dealing with mine askfjsad asdfsadf
              asdfsadfaasdf…
            </p>
            <button className="read-more-btn">Read Post</button>
          </div>
        </a>
      </div>
    </React.Fragment>
  );
};

export default BlogCard;
