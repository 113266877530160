import React, { useEffect } from "react";
import "./home.css";
import { useMediaQuery } from "react-responsive";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import BlogSection from "../../components/BlogSection/BlogSection";
import TransformationCarousel from "../../components/transformationCarousel/transformationCarousel";
import { Splide, SplideSlide } from "@splidejs/react-splide";

function Home() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)", // Adjust this value as per your design breakpoints
  });

  return (
    <React.Fragment>
      {/* hero section */}
      <section className="hero-section">
        {/* <div className="overlay"></div> */}
        <div className="container">
          <div className="flex-wrapper">
            <Header />
            <div className="w-idth-c-ustom">
              <span className="hero-heading-thin">
                ATHLETE, EX FOOTBALLER, PERSONAL TRAINER, COACH
              </span>
              <h1
                className="hero-heading-stong"
                style={{
                  color: "#FFFFFF",
                  textTransform: "uppercase",
                  lineHeight: "1.1em",
                  margin: "10px 0px 0px 0px",
                  fontWeight: "700",
                }}
              >
                GET FIT, TAKE CONTROL, LIVE ON PURPOSE, & MAKE A DIFFERENCE
              </h1>
              <button className="appointment-button">
                BOOK YOUR APPOINTMENT
              </button>
            </div>
            <div>{/* Content here */}</div>
          </div>
        </div>
      </section>
      {/* Naji intro  */}
      <section className="widi-intro-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-block d-lg-none">
              <img
                src="../../assets/images/naji-intro-pic.png"
                className="img-fluid"
                loading="lazy"
              />
            </div>
            <div className="col-md-6 offset-lg-6">
              <h2 className="second-hero-subheading">
                HI, I’M WIDAD <br />
                <span className="second-hero-mainheading">NAJI.</span>
              </h2>
              <p className="second-hero-para">
                Hello, I am Widad Naji, originally from Germany, a former
                footballer turned personal fitness trainer and coach. With a
                specialization in agility performance training and plyometric
                exercises, I bring a unique blend of athletic expertise to my
                clients. I strongly believe in transforming lives through
                fitness and aim to inspire others to achieve their health and
                wellness goals. My background as a footballer provides me with
                valuable insights into the demands of elite sports and enables
                me to draft training programs that enhance performance and
                prevent injuries.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Video Sections */}
      <section className="video-bg">
        <div className="video-overlay"></div>
        {isDesktopOrLaptop && (
          <video className="video-container" autoPlay muted playsInline loop>
            <source src="../assets/videos/Widi Website.webm" type="video/mp4" />
            <source src="../assets/videos/Widi Website.webm" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        )}
        {isMobile && (
          <video className="video-container" autoPlay muted playsInline loop>
            <source src="../assets/videos/Widi Website.mp4" type="video/mp4" />
            <source src="../assets/videos/Widi Website.mp4" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        )}
      </section>
      {/* Transformations  */}
      <section className="transformation-section">
        <h2 className="transformation-heading">
          Changing Lives
          <br />
          <span>Around the World</span>
        </h2>
        <TransformationCarousel />
      </section>
      {/* Insta section */}
      <section className="insta-section">
        <div className="container">
          <h1 className="insta-section-mainheading">Follow Me</h1>
          <p className="insta-section-subheading">
            Check out my latest Instagram videos below!
          </p>
          <div className="row g-3" style={{ marginTop: "2rem" }}>
            <div className="col-6 col-md-4 col-lg-3">
              <a
                target="_blank"
                href="https://www.instagram.com/reel/C4oYkYghGRw/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                className="insta-reel-card"
              >
                <img
                  src="../../assets/images/trainer1.PNG"
                  className="insta-reel-thumnail"
                  loading="lazy"
                />
                <img
                  src="../../assets/images/play-button.png"
                  className="absolute-centered-play-btn"
                />
              </a>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <a
                target="_blank"
                href="https://www.instagram.com/reel/C4IHRgwB1C5/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                className="insta-reel-card"
              >
                <img
                  src="../../assets/images/trainer2.PNG"
                  className="insta-reel-thumnail"
                  loading="lazy"
                />
                <img
                  src="../../assets/images/play-button.png"
                  className="absolute-centered-play-btn"
                />
              </a>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <a
                target="_blank"
                href="https://www.instagram.com/reel/C2DhCXdhcUd/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                className="insta-reel-card"
              >
                <img
                  src="../../assets/images/trainer3.PNG"
                  className="insta-reel-thumnail"
                  loading="lazy"
                />
                <img
                  src="../../assets/images/play-button.png"
                  className="absolute-centered-play-btn"
                />
              </a>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <a
                target="_blank"
                href="https://www.instagram.com/reel/C1RQUTZBYrn/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                className="insta-reel-card"
              >
                <img
                  src="../../assets/images/trainer4.PNG"
                  className="insta-reel-thumnail"
                  loading="lazy"
                />
                <img
                  src="../assets/images/play-button.png"
                  className="absolute-centered-play-btn"
                />
              </a>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <a
                target="_blank"
                href="https://www.instagram.com/reel/CupoKHdhPqa/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                className="insta-reel-card"
              >
                <img
                  src="../../assets/images/trainer5.PNG"
                  className="insta-reel-thumnail"
                  loading="lazy"
                />
                <img
                  src="../../assets/images/play-button.png"
                  className="absolute-centered-play-btn"
                />
              </a>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <a
                target="_blank"
                href="https://www.instagram.com/p/Bs8i7sRhHtn/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                className="insta-reel-card"
              >
                <img
                  src="../../assets/images/trainer6.PNG"
                  className="insta-reel-thumnail"
                  loading="lazy"
                />
                <img
                  src="../../assets/images/play-button.png"
                  className="absolute-centered-play-btn"
                />
              </a>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <a
                target="_blank"
                href="https://www.instagram.com/p/CT1hh1nBgLF/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                className="insta-reel-card"
              >
                <img
                  src="../../assets/images/trainer7.PNG"
                  className="insta-reel-thumnail"
                  loading="lazy"
                />
                <img
                  src="../../assets/images/play-button.png"
                  className="absolute-centered-play-btn"
                />
              </a>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <a
                target="_blank"
                href="https://www.instagram.com/reel/C1cn_byh2vb/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                className="insta-reel-card"
              >
                <img
                  src="../../assets/images/trainer8.PNG"
                  className="insta-reel-thumnail"
                  loading="lazy"
                />
                <img
                  src="../../assets/images/play-button.png"
                  className="absolute-centered-play-btn"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Motivational quotes */}
      <section className="quotation-section">
        <div className="container">
          <Splide
            className="quotation-carousel"
            options={{
              type: "loop",
              perPage: 1,
              perMove: 1,
              pagination: false,
              arrows: true,
              autoplay: true,
              interval: 3000,
            }}
          >
            <SplideSlide>
              <div className="item">
                <h2>
                  Fitness is not about being better than someone else; it's
                  about being better than you used to be.
                </h2>
                <cite>- Widad Naji</cite>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <h2>
                  Fall in love with taking care of yourself – mind, body, and
                  spirit.
                </h2>
                <cite>- Widad Naji</cite>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <h2>Don't stop when you're tired; stop when you're done.</h2>
                <cite>- Widad Naji</cite>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <h2>
                  Fitness is not about being better than someone else; it's
                  about being better than you used to be.
                </h2>
                <cite>- Widad Naji</cite>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>
      {/* Blogs section */}
      <section className="blogs-section">
        <div className="container">
          <h2 className="blogs-main-heading">Widad's blog</h2>
          <p className="blogs-para">
            Dive into our blog for the latest in workout routines, nutrition
            advice, and wellness strategies to elevate your fitness experience.
          </p>
          {/* navs and tabs */}
          <ul className="nav nav-tabs blog-nav" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="allPosts-tab"
                data-bs-toggle="tab"
                data-bs-target="#allPosts-tab-pane"
                type="button"
                role="tab"
                aria-controls="allPosts-tab-pane"
                aria-selected="true"
              >
                All Posts
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="gettingFit-tab"
                data-bs-toggle="tab"
                data-bs-target="#gettingFit-tab-pane"
                type="button"
                role="tab"
                aria-controls="gettingFit-tab-pane"
                aria-selected="false"
              >
                Getting Fit
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="stayMotivated-tab"
                data-bs-toggle="tab"
                data-bs-target="#stayMotivated-tab-pane"
                type="button"
                role="tab"
                aria-controls="stayMotivated-tab-pane"
                aria-selected="false"
              >
                Stay Motivated
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="whatToEat"
                data-bs-toggle="tab"
                data-bs-target="#whatToEat-pane"
                type="button"
                role="tab"
                aria-controls="whatToEat-pane"
                aria-selected="false"
              >
                What to Eat
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="mindset-tab"
                data-bs-toggle="tab"
                data-bs-target="#mindset-tab-pane"
                type="button"
                role="tab"
                aria-controls="mindset-tab-pane"
                aria-selected="false"
              >
                Mindset
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="allPosts-tab-pane"
              role="tabpanel"
              aria-labelledby="allPosts-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
            <div
              className="tab-pane fade"
              id="gettingFit-tab-pane"
              role="tabpanel"
              aria-labelledby="gettingFit-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
            <div
              className="tab-pane fade"
              id="stayMotivated-tab-pane"
              role="tabpanel"
              aria-labelledby="stayMotivated-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
            <div
              className="tab-pane fade"
              id="whatToEat-pane"
              role="tabpanel"
              aria-labelledby="whatToEat-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
            <div
              className="tab-pane fade"
              id="mindset-tab-pane"
              role="tabpanel"
              aria-labelledby="mindset-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
          </div>
          <button className="all-posts-btn">View All Posts</button>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

export default Home;
