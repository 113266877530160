import React, { useEffect } from "react";
import "./App.css";
import {
  Outlet,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import Router from "./Router";


function App() {
  return (
    <React.Fragment>
      <RouterProvider router={Router} />
    </React.Fragment>
  );
}

export default App;
