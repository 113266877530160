import React, { useEffect, useState } from "react";
import BlogCard from "../BlogCard/BlogCard";
import Pagination from "../pagination/pagination";

const BlogSection = () => {
  const [data, setData] = useState([1, 2, 3, 4, 5, 6, 4, 4, 4, 4, 4]);
  // pagination
  const [numPosts, setNumPosts] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 767) {
        setNumPosts(2);
      } else if (width >= 768 && width <= 1023) {
        setNumPosts(4);
      } else {
        setNumPosts(6);
      }
    };

    handleResize(); // Initial check on component mount

    window.addEventListener("resize", handleResize); // Listen to window resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on component unmount
    };
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  //   const ITEMS_PER_PAGE = 6;
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  // Assuming you have a list of items
  const totalItems = data.length; // Total number of items
  const totalPages = Math.ceil(totalItems / numPosts);
  const startIndex = (currentPage - 1) * numPosts;
  const endIndex = Math.min(startIndex + numPosts, totalItems);
  const itemsToShow = [...data].slice(startIndex, endIndex);

  return (
    <React.Fragment>
      <div className="row justify-fluid g-4">
        {itemsToShow?.map((item, index) => (
          <BlogCard key={index} />
        ))}
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </React.Fragment>
  );
};

export default BlogSection;
