import React from "react";
import "@splidejs/react-splide/css";
import "./transformationCarousel.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const transformationCarousel = () => {
  return (
    <React.Fragment>
      <Splide className="tranformation-carousel"
        options={{
          type: "loop",
          perPage: 4,
          perMove: 1,
          gap: "1rem",
          pagination: true,
          arrows: true,
          autoplay: true,
          interval: 3000,
          breakpoints: {
            1023: {
              perPage: 2
            },
            767: {
              perPage: 1,
            },
          },
        }}
      >
        <SplideSlide>
          <div className="item">
            <img src="../../assets/images/before_afters19.jpg" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="item">
            <img src="../../assets/images/before_afters21.jpg" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="item">
            <img src="../../assets/images/before_afters23.jpg" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="item">
            <img src="../../assets/images/before_afters244.jpg" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="item">
            <img src="../../assets/images/before_afters1111.jpg" />
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="item">
            <img src="../../assets/images/transformation1.png" />
          </div>
        </SplideSlide>
      </Splide>
    </React.Fragment>
  );
};

export default transformationCarousel;
