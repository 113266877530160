import React, { useState, useEffect } from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [pagesToShow, setPagesToShow] = useState([]);

  useEffect(() => {
    const generatePagesToShow = () => {
      const maxPagesToShow = 3; // Adjust this value to control the number of pages shown
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
      if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }
      const pages = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
      setPagesToShow(pages);
    };
    generatePagesToShow();
  }, [currentPage, totalPages]);

  const handleClick = (page) => {
    onPageChange(page);
  };

  const renderPaginationButtons = () => {
    return pagesToShow.map((page) => (
      <li
        className={
          currentPage === page ? "page-item activePagination" : "page-item"
        }
        key={page}
      >
        {/* disabled={currentPage === page} */}
        <a onClick={() => handleClick(page)} className="page-link">
          {page}
        </a>
      </li>
    ));
  };

  return (
    <React.Fragment>
      <nav aria-label="Page navigation example">
        <ul
          className="pagination align-items-end mb-0"
          style={{ columnGap: "0.75rem" }}
        >
          <li
            className={currentPage === 1 ? "page-item disabled" : "page-item"}
          >
            {/* disabled={currentPage === 1} */}
            <a
              className="page-link"
              href="#"
              aria-label="Previous"
              onClick={() => handleClick(currentPage - 1)}
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          {pagesToShow[0] > 1 && (
            <React.Fragment>
              <li className="page-item">
                <a onClick={() => handleClick(1)} className="page-link">
                  1
                </a>
              </li>
              {pagesToShow[0] > 2 && (
                <li className="page-item disabled">
                  <a className="page-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="4"
                      viewBox="0 0 12 4"
                      fill="none"
                    >
                      <path
                        d="M1.19925 3.18441C0.857917 3.18441 0.57525 3.07241 0.35125 2.84841C0.137917 2.61374 0.03125 2.33107 0.03125 2.00041C0.03125 1.65907 0.137917 1.37641 0.35125 1.15241C0.57525 0.928406 0.857917 0.816406 1.19925 0.816406C1.54058 0.816406 1.81792 0.928406 2.03125 1.15241C2.25525 1.37641 2.36725 1.65907 2.36725 2.00041C2.36725 2.33107 2.25525 2.61374 2.03125 2.84841C1.81792 3.07241 1.54058 3.18441 1.19925 3.18441Z"
                        fill="#595959"
                      />
                      <path
                        d="M5.99988 3.18441C5.65854 3.18441 5.37587 3.07241 5.15188 2.84841C4.93854 2.61374 4.83187 2.33107 4.83187 2.00041C4.83187 1.65907 4.93854 1.37641 5.15188 1.15241C5.37587 0.928406 5.65854 0.816406 5.99988 0.816406C6.34121 0.816406 6.61854 0.928406 6.83187 1.15241C7.05587 1.37641 7.16787 1.65907 7.16787 2.00041C7.16787 2.33107 7.05587 2.61374 6.83187 2.84841C6.61854 3.07241 6.34121 3.18441 5.99988 3.18441Z"
                        fill="#595959"
                      />
                      <path
                        d="M10.8005 3.18441C10.4592 3.18441 10.1765 3.07241 9.9525 2.84841C9.73917 2.61374 9.6325 2.33107 9.6325 2.00041C9.6325 1.65907 9.73917 1.37641 9.9525 1.15241C10.1765 0.928406 10.4592 0.816406 10.8005 0.816406C11.1418 0.816406 11.4192 0.928406 11.6325 1.15241C11.8565 1.37641 11.9685 1.65907 11.9685 2.00041C11.9685 2.33107 11.8565 2.61374 11.6325 2.84841C11.4192 3.07241 11.1418 3.18441 10.8005 3.18441Z"
                        fill="#595959"
                      />
                    </svg>
                  </a>
                </li>
              )}
            </React.Fragment>
          )}
          {renderPaginationButtons()}
          {pagesToShow[pagesToShow.length - 1] < totalPages && (
            <React.Fragment>
              {pagesToShow[pagesToShow.length - 1] < totalPages - 1 && (
                <li className="page-item disabled">
                  <a className="page-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="4"
                      viewBox="0 0 12 4"
                      fill="none"
                    >
                      <path
                        d="M1.19925 3.18441C0.857917 3.18441 0.57525 3.07241 0.35125 2.84841C0.137917 2.61374 0.03125 2.33107 0.03125 2.00041C0.03125 1.65907 0.137917 1.37641 0.35125 1.15241C0.57525 0.928406 0.857917 0.816406 1.19925 0.816406C1.54058 0.816406 1.81792 0.928406 2.03125 1.15241C2.25525 1.37641 2.36725 1.65907 2.36725 2.00041C2.36725 2.33107 2.25525 2.61374 2.03125 2.84841C1.81792 3.07241 1.54058 3.18441 1.19925 3.18441Z"
                        fill="#595959"
                      />
                      <path
                        d="M5.99988 3.18441C5.65854 3.18441 5.37587 3.07241 5.15188 2.84841C4.93854 2.61374 4.83187 2.33107 4.83187 2.00041C4.83187 1.65907 4.93854 1.37641 5.15188 1.15241C5.37587 0.928406 5.65854 0.816406 5.99988 0.816406C6.34121 0.816406 6.61854 0.928406 6.83187 1.15241C7.05587 1.37641 7.16787 1.65907 7.16787 2.00041C7.16787 2.33107 7.05587 2.61374 6.83187 2.84841C6.61854 3.07241 6.34121 3.18441 5.99988 3.18441Z"
                        fill="#595959"
                      />
                      <path
                        d="M10.8005 3.18441C10.4592 3.18441 10.1765 3.07241 9.9525 2.84841C9.73917 2.61374 9.6325 2.33107 9.6325 2.00041C9.6325 1.65907 9.73917 1.37641 9.9525 1.15241C10.1765 0.928406 10.4592 0.816406 10.8005 0.816406C11.1418 0.816406 11.4192 0.928406 11.6325 1.15241C11.8565 1.37641 11.9685 1.65907 11.9685 2.00041C11.9685 2.33107 11.8565 2.61374 11.6325 2.84841C11.4192 3.07241 11.1418 3.18441 10.8005 3.18441Z"
                        fill="#595959"
                      />
                    </svg>
                  </a>
                </li>
              )}
              <a onClick={() => handleClick(totalPages)} className="page-link">
                {totalPages}
              </a>
            </React.Fragment>
          )}
          <li
            className={
              currentPage === totalPages ? "page-item disabled" : "page-item"
            }
          >
            {/* disabled={currentPage === totalPages} */}
            <a
              className="page-link"
              href="#"
              aria-label="Next"
              onClick={() => handleClick(currentPage + 1)}
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default Pagination;
