import React from "react";
import "./myStory.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const myStory = () => {
  return (
    <React.Fragment>
      {/* page intro */}
      <section className="blog-hero">
        <div className="container h-100">
          <div className="d-flex flex-column justify-content-between align-items-center h-100">
            <Header />
            <h2 className="page-heading">MY STORY</h2>
            <div></div>
          </div>
        </div>
      </section>
      {/* page body */}
      <section className="container" style={{ marginTop: "5rem" }}>
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ columnGap: "0.5rem" }}
        >
          <span className="pink-divider"></span>
          <h3 className="widi-story-heading">FROM THE STREETS OF MOROCCO TO</h3>
          <span className="pink-divider"></span>
        </div>
        <div>
          <h2 className="pink-heading">ENERGETIC METROPOLIS OF DUBAI</h2>
        </div>
        <div
          className="row justify-content-evenly"
          style={{ marginTop: "40px" }}
        >
          <div className="col-md-5">
            {/* <h2 className="naji-sccess-intro-heading">
              Naji Continues to Changing Lives:
            </h2> */}
            <p className="naji-sucess-intro-para mb-0">
              From lethargy to vitality, she transformed her life's script. Now,
              as a fitness trainer, she inspires others to rewrite theirs.
              Embrace change, for within it lies the power to sculpt a life of
              purpose and vigor.
              <br /> <br />
            </p>
            <h2 className="pink-heading-sm">I’VE BEEN THERE, TOO</h2>
            <p className="naji-sucess-intro-para">
              <br />
              As I sit back and reflect on my journey, it feels like reading a
              novel – Each chapter has had surprises, tough times to get
              through, and moments of success. I'm Widad Naji, and my story is
              all about staying strong, following my passions, and never giving
              up.
              <br /> <br />
              I was born in Morocco, where the streets were lively and colorful.
              When I was eight, my family moved to Germany. Everything there was
              new and different, and that's where I fell in love with playing
              football.
              <br /> <br />
              As soon as I kicked my first ball, I realised that this lovely
              game would be my beacon. Whether it was practicing on the pitch or
              running after the ball through Frankfurt's streets, football
              became my haven and my spot where I felt completely alive.
              <br /> <br />
              As time went by, coaches and scouts noticed how much effort and
              skill I put into playing football. Soon enough, I found myself
              wearing the uniform of FSV Frankfurt, playing for my team with all
              my heart. Those days were full of intense matches, where we'd work
              up a sweat, feel the rush of excitement, and savor the joy of
              winning.
              <br /> <br />
              Even though I really loved playing football, I had this strong
              feeling that there was something else out there for me. So, when I
              turned 24, I decided to stop playing and try something new. I
              wanted to turn my love for fitness into a job that would really
              matter to me.
              <br /> <br />
              I worked really hard to learn all about fitness training, like
              exercising early in the morning and studying late into the night.
              I was getting closer and closer to my dreams. Then, in 2017, I
              ended up in Dubai by chance. In Dubai, with its tall buildings and
              lots of chances, I discovered my real passion as a fitness coach.
              Drawing from my own time as an athlete, I committed to assisting
              others in reaching their best, both in body and mind.
              <br /> <br />
              I've been lucky to help lots of different people, like busy bosses
              who want to feel better and young athletes who dream big. Seeing
              them reach their goals makes me really happy.
              <br /> <br />
              It has been an amazing journey for me from the streets of Morocco
              to the energetic metropolis of Dubai. And I am excited for the
              experiences that lie ahead as I consider the innumerable chapters
              that are still to be written. Because the trip in the realm of
              fitness is a never-ending voyage of growth, discovery, and
              limitless opportunities, it never really ends.
            </p>
          </div>
          <div className="col-md-5 order-first order-md-last img-wrapper">
            <img
              src="../../../assets/images/widi-story2.jpg"
              className="widi-img-lg"
            />
            {/* <img
              src="../../../assets/images/widi-story1.jpg"
              className="widi-img-sm"
            /> */}
          </div>
        </div>
      </section>
      {/* quotaion */}
      <section className="container">
        <h1 className="quotation-heading">
          "Your body can stand almost anything; it's your mind that you have to
          convince."
        </h1>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default myStory;
