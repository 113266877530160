import React from "react";
import "./blog.css";
import Header from "../../components/header/Header";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import BlogSection from "../../components/BlogSection/BlogSection";
import Footer from "../../components/footer/Footer";

const Blog = () => {
  return (
    <React.Fragment>
      {/* page intro */}
      <section className="blog-hero">
        <div className="container h-100">
          <div className="d-flex flex-column justify-content-between align-items-center h-100">
            <Header />
            <div className="para-width text-center">
              <h2 className="page-heading">Widad's Blog</h2>
              <p className="page-para">
                Dive into our blog for the latest in workout routines, nutrition
                advice, and wellness strategies to elevate your fitness
                experience.
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </section>
      {/* brand section */}
      <section className="brand-carousel-bg">
        <div className="container">
          <Splide
            className="brand-carousel"
            options={{
              type: "loop",
              perPage: 6,
              perMove: 1,
              gap: "1rem",
              pagination: false,
              arrows: false,
              autoWidth: true,
              autoplay: true,
              interval: 2000,
              breakpoints: {
                768: {
                  perPage: 1,
                },
              },
            }}
          >
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/DETOX-ORGANIX.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/entrepreneur.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/espn-radio.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/forbes.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/harvard.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/mens-fitness.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/womans-world.png" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>
      {/* blog section */}
      <section className="blogs-section">
        <div className="container">
          {/* <h2 className="blogs-main-heading">Widad's blog</h2>
          <p className="blogs-para">
            Dive into our blog for the latest in workout routines, nutrition
            advice, and wellness strategies to elevate your fitness experience.
          </p> */}
          {/* navs and tabs */}
          <ul className="nav nav-tabs blog-nav" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="allPosts-tab"
                data-bs-toggle="tab"
                data-bs-target="#allPosts-tab-pane"
                type="button"
                role="tab"
                aria-controls="allPosts-tab-pane"
                aria-selected="true"
              >
                All Posts
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="gettingFit-tab"
                data-bs-toggle="tab"
                data-bs-target="#gettingFit-tab-pane"
                type="button"
                role="tab"
                aria-controls="gettingFit-tab-pane"
                aria-selected="false"
              >
                Getting Fit
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="stayMotivated-tab"
                data-bs-toggle="tab"
                data-bs-target="#stayMotivated-tab-pane"
                type="button"
                role="tab"
                aria-controls="stayMotivated-tab-pane"
                aria-selected="false"
              >
                Stay Motivated
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="whatToEat"
                data-bs-toggle="tab"
                data-bs-target="#whatToEat-pane"
                type="button"
                role="tab"
                aria-controls="whatToEat-pane"
                aria-selected="false"
              >
                What to Eat
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="mindset-tab"
                data-bs-toggle="tab"
                data-bs-target="#mindset-tab-pane"
                type="button"
                role="tab"
                aria-controls="mindset-tab-pane"
                aria-selected="false"
              >
                Mindset
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="allPosts-tab-pane"
              role="tabpanel"
              aria-labelledby="allPosts-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
            <div
              className="tab-pane fade"
              id="gettingFit-tab-pane"
              role="tabpanel"
              aria-labelledby="gettingFit-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
            <div
              className="tab-pane fade"
              id="stayMotivated-tab-pane"
              role="tabpanel"
              aria-labelledby="stayMotivated-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
            <div
              className="tab-pane fade"
              id="whatToEat-pane"
              role="tabpanel"
              aria-labelledby="whatToEat-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
            <div
              className="tab-pane fade"
              id="mindset-tab-pane"
              role="tabpanel"
              aria-labelledby="mindset-tab"
              tabIndex="0"
            >
              <BlogSection />
            </div>
          </div>
          {/* <button className="all-posts-btn">View All Posts</button> */}
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Blog;
