import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const { pathname, hash } = useLocation();
  const [path, setPath] = useState(pathname.replace("/", "").split("/")[0]);

  useEffect(() => {
    setPath(pathname.replace("/", "").split("/")[0]);
  }, [pathname]);

  const getNavLinkClass = (paths) => {
    return paths.includes(pathname)
      ? "nav-link nav-link-active"
      : "nav-link";
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-dark bg-trans">
        {/* Navbar toggle button  */}
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <a className="navbar-brand ms-auto d-block d-lg-none" href="/">
          <img
            src="../assets/images/widi-logo-plan.png"
            alt="Logo"
            height="30"
            className="d-inline-block align-text-top widad-logo"
          />
        </a>

        {/* Left side items  */}
        <div
          className="collapse navbar-collapse justify-content-start"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className={getNavLinkClass(["/"])} href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className={getNavLinkClass(["/my-story"])} href="my-story">
                My Story
              </a>
            </li>
            <li className="nav-item">
              <a className={getNavLinkClass(["/blogs"])} href="blogs">
                Blog
              </a>
            </li>
          </ul>
        </div>

        {/* Logo in the middle  */}
        <a className="navbar-brand d-none d-lg-block ms-auto mx-lg-auto" href="/">
          <img
            src="../assets/images/widi-logo-plan.png"
            alt="Logo"
            height="30"
            className="d-inline-block align-text-top widad-logo"
          />
        </a>

        {/* Right side items  */}
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className={getNavLinkClass(["/contact"])} href="contact">
                Contact
              </a>
            </li>
            <li className="nav-item">
              <a className={getNavLinkClass(["/success-stories"])} href="success-stories">
                Success Stories
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="#">
                Ham
              </a>
            </li> */}
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Header;
