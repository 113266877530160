import React from "react";
import "./SuccessStories.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TransformationCarousel from "../../components/transformationCarousel/transformationCarousel";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const SuccessStories = () => {
  return (
    <React.Fragment>
      {/* page intro */}
      <section className="blog-hero">
        <div className="container h-100">
          <div className="d-flex flex-column justify-content-between align-items-center h-100">
            <Header />
            <div className="para-width text-center">
              <h2 className="page-heading">SUCCESS STORIES</h2>
              <p className="page-para">
                Explore inspiring tales of transformation and achievement from
                individuals who have journeyed with Widad, experiencing
                remarkable fitness milestones.
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </section>
      {/* brand section */}
      <section className="brand-carousel-bg">
        <div className="container">
          <Splide
            className="brand-carousel"
            options={{
              type: "loop",
              perPage: 6,
              perMove: 1,
              gap: "1rem",
              pagination: false,
              arrows: false,
              autoWidth: true,
              autoplay: true,
              interval: 2000,
              breakpoints: {
                768: {
                  perPage: 1,
                },
              },
            }}
          >
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/DETOX-ORGANIX.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/entrepreneur.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/espn-radio.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/forbes.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/harvard.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/mens-fitness.png" />
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <img src="../../assets/images/womans-world.png" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>
      {/* success stories */}
      <section className="success-stories-bg">
        <TransformationCarousel />
      </section>
      <section className="container" style={{ marginTop: "40px" }}>
        <div className="row justify-content-evenly">
          <div className="col-md-5">
            <img
              src="../../../assets/images/Micahel-Morelli-Instagram-683x1024.jpg"
              className="widad-fluid"
            />
          </div>
          <div className="col-md-5">
            <h2 className="naji-sccess-intro-heading">
              Naji Continues to Changing Lives:
            </h2>
            <p className="naji-sucess-intro-para">
              Once a star on the football field, Widad Naji transitioned from
              the world of sports to the realm of fitness training, crafting a
              remarkable success story that continues to inspire many. <br />
              Widad began a new career as a certified personal fitness trainer
              after discovering a natural interest in fitness and a desire to
              assist others in reaching their health goals. <br />
              Word of Widad's remarkable coaching spread quickly, bringing
              people from all walks of life. Whether they were prospective
              athletes aiming to boost their performance or individuals seeking
              to improve their overall well-being, Widad personalized her
              programs to match their specific needs and objectives. <br />
              Widad's reputation grew, and so did her impact. Hundreds of
              individuals attended her training classes, drawn not just by the
              prospect of physical improvement, but also by Widad's contagious
              excitement and steadfast support. Her ability to inspire and
              empower her clientele became the signature of her success.
            </p>
          </div>
        </div>
        <div className="row justify-content-evenly" style={{marginTop: "40px", marginBottom: "40px"}}>
          <div className="col-md-5">
            <h2 className="naji-sccess-intro-heading">Instagram :</h2>
            <p className="naji-sucess-intro-para">
              In the midst of the rapidly evolving social media landscape, Widad
              Naji emerged as a leading force on Instagram, driven by an
              unrelenting commitment to consistency and connection. <br />
              Widad recognized Instagram's potential as a platform for sharing
              her health and fitness message early on. With steady commitment,
              she went on a path of daily posting, never wavering in her
              dedication for nearly ten years. <br />
              Even in the early days, when the future of social media was
              uncertain, Widad stayed steadfast, ensuring that her Instagram
              presence remained consistent. There were evenings when she awoke
              at 2 a.m. to share her ideas, ensuring that her international
              audience benefit from her experience. <br />
              Widad's efforts paid off over time, as her following grew
              demonstrating the resonance of her message and the effect of her
              work. When considering the scope of her multiple successful
              enterprises, her impact expands even further, approaching 77k
              followers on Instagram alone.
            </p>
          </div>
          <div className="col-md-5 order-first order-md-last">
            <img
              src="../../../assets/images/widi-insta-ss.png"
              className="widad-insta-ss-fluid"
            />
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default SuccessStories;
